<template>
  <iframe
    :src="httpurl"
    scrolling="auto"
    frameborder="0"
    :style="{ height: '100%', width: '100%' }"
  />
  <div class="Dialog" v-show="Dialogshow">
    <div class="title">温馨提示</div>
    <div class="content">本地区暂无法办理，为您推荐最新活动。</div>
    <div class="bottondiv">确认 &nbsp;({{ time }})</div>
  </div>
</template>

<script>
import { getJumpOption } from "@/services/jump";
import { ref } from "vue";
export default {
  setup() {
    const httpurl = ref();
    const Dialogshow = ref(false);
    let arr = [
      "?digest=9c76daa9e2504e93",
      "?digest=cac236d19641e93c",
      "?digest=04195ebb750220a6",
      "?digest=aca9d137292a275e",
      "?digest=67b2efdad426811c",
      "?digest=29b5234af3b3f2cd",
      "?digest=642974e41cf210e1",
      "?digest=226ac578c5fb40e9",
      "?digest=7406246175127e61",
      "?digest=1f439a43106467c3",
      "?digest=bcfa296d7735ac6f",
      "?digest=f61f477af7770af9",
      "?digest=e5bbdb33b5e80943",
      "?digest=2b7ad93910617c07",
      "?digest=a799aca905c837b8",
      "?digest=6c4f4836239b7cba",
      "?digest=19a092fcaa85a2f4",
      "?digest=a03a0088ba75d74e",
      "?digest=38f475e297a07fd7",
    ];
    let time = ref(1);

    getJumpOption()
      .then((res) => {
        const { data, msg } = res;
        //如果msg等于'WO_SHIELD_PROVINCE'，代表需要触发弹窗，否则不触发弹窗
        if (msg === "WO_SHIELD_PROVINCE") {
          Dialogshow.value = true;
          let timer = setInterval(() => {
            --time.value;
            if (time.value <= 0) {
              clearInterval(timer);
              Dialogshow.value = false;
              // 如果参数是arr数组中的，那么就走iframe嵌套，否则直接跳转
              if (arr.includes(location.search)) {
                console.log(decodeURIComponent(data), "1223");
                httpurl.value = decodeURIComponent(data);
              } else {
                location.replace(decodeURIComponent(data));
              }
            }
          }, 1000);
        } else {
          // 如果参数是arr数组中的，那么就走iframe嵌套，否则直接跳转
          if (arr.includes(location.search)) {
            console.log(decodeURIComponent(data), "1223");
            httpurl.value = decodeURIComponent(data);
          } else {
            location.replace(decodeURIComponent(data));
          }
        }
      })
      .catch((err) => {
        alert(err?.message || err?.msg || "请求失败");
      });

    console.log(location.search);
    // if (location.search == '?a=123') {

    // httpurl.value = 'https://oms.flow.wostore.cn/web/qudao/staticPage/hpjy_free_flow.html?channelid=33231&subchannelid=vikp3&pushid=jy'
    // }

    return {
      httpurl,
      Dialogshow,
      time,
    };
  },
};
</script>
<style>
html,
body {
  background: #f7f8f8;
  line-height: 1.2;
  font-size: 0.28rem;
  height: 100vh;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}
.Dialog {
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
  /* height: 200px; */
  /* background: pink; */
  transform: translateY(-50%);
  border-radius: 20px;
  box-shadow: 0px 0px 3px 0px #999;
}
.title {
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  /* border-bottom: 1px solid #eee; */
  height: 50px;
  line-height: 50px;
}
.content {
  height: 40px;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
  box-sizing: border-box;
}
.bottondiv {
  color: red;
  height: 40px;
  font-size: 15px;
  text-align: center;
  line-height: 40px;
  border-top: 1px solid #eee;
}
</style>