import request from '@/shared/request'

/**
 * 获取跳转链接
 * @param {*} data 
 * @returns 
 */
export function getJumpOption (data) {
  return request({
    url: '/jump/jumpUrl',
    method: 'post',
    data
  })
}