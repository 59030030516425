
/**
 * 获取路由参数
 * @returns 
 */
export function getQuery (name) {
  let query = {}
  location.search.slice(1).split('&').forEach((v) => {
    let arr = v.split('=')
    query[arr[0]] = arr[1]
  })
  if (!!name && typeof name === 'string') {
    return query[name]
  }

  return query
}