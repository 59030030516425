import md5 from '@/utils/md5'
/**
 * 过滤无效参数
 * @param {*} config 
 * @returns 
 */
export function filterUndefined (config) {
  const data = config.data
  if (typeof data === 'object' && !(data instanceof FormData)) {
    config.data = Object.keys(config.data)
      .reduce((obj, key) => {
        const d = (data)[key]
        if (!(['', undefined].includes(d))) {
          obj[key] = d
        }
        return obj
      }, {})
  }

  return config
}

/**
 * 生成签名
 * @param {*} obj 
 * @param {*} options 
 * @returns 
 */

export const suffix = '2bf2df6d16e96f59c56992bac55a6e94'

export function generateSign (obj, options = {}) {
  let str = ''
  if (typeof obj === 'string') {
    str += suffix
  } else if (typeof obj === 'object') {
    const keys = Object.keys(obj).sort()
    const exclude = Array.isArray(options.exclude) ? options.exclude : []
    const include = Array.isArray(options.include) ? options.include : []

    for (const key of keys) {
      const value = obj[key]
      if (key === 'sign' || ['', null, undefined].includes(value) || exclude.includes(key) || (value.length > 200 && !include.includes(key))) {
        continue
      }

      str += (key + encodeURIComponent(obj[key]))
    }
  }
  str += suffix
  return md5(str).toLocaleLowerCase()
}