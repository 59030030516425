import { getQuery } from '@/utils/route'
import axios from 'axios'
import { filterUndefined, generateSign } from './utils'

export const baseURL = process.env.VUE_APP_BASE_API

axios.defaults.baseURL = baseURL
axios.defaults.timeout = 1000 * 1000

export function handleResponse (res) {
  const { data, data: { code } } = res

  if (code === 0) {
    return data
  }

  return Promise.reject(data)
}


/**
 * 通用请求配置
 */
const instance = axios.create()
instance.interceptors.response.use(handleResponse)
instance.interceptors.request.use(filterUndefined)

/** */
function request (config, options) {

  let data
  if (config.method === 'post') {
    data = config.data || (config.data = {})

  } else {
    data = config.params || (config.params = {})
  }

  data.ts = parseInt(Date.now() / 1000)
  data.digest = getQuery('digest')

  data.sign = generateSign(data, {
    exclude: options?.excludeSignKeys,
    include: options?.includeSignKeys
  })


  return instance(config)
    .catch(err => {
      if (options?.isHandleError !== false) {
        // const msg = err?.message || err?.msg || err?.response?.data?.message || '请求失败'
        // message.error(msg)
      }
      return Promise.reject(err)
    })
}

export default request